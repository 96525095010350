import React, { useState, useCallback, useEffect } from "react"
import {
  LegacyCard,
  FormLayout,
  TextField,
  Layout,
  Text,
  Form,
  InlineError,
  Box,
} from "@shopify/polaris"
import update from "immutability-helper"
import SmartRules from '../../offers/smartRules'
import CardDivider from "../../../../components/cardDivider"
const HidePaymentMethodsForm = ( props ) => {
const { token, host, shop,location, error, state, setState, productPickerShow, setProductPickerShow, currencySymbol, isCreating, isEditing, isSubmitting, hasUnsavedChanges, handleFormSubmit, handleCustomContentDelete } = props
  const addSmartRule =  () => {
    const conditions = {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
  }
    setState(update(state, { triggers: { $set: conditions }}))
  }
  //const formValues = getValues(fields)
  /* The form layout, created using Polaris and App Bridge components. */
  return (
      <Layout>
        <Layout.Section>
          {/*isCreating && (
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          )*/}
          <div style={{ marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.name 
                  }
                  onSubmit={handleFormSubmit}>
            <FormLayout>
              <LegacyCard sectioned>
                <Text variant="bodyMd" as="h6" fontWeight="semibold">Hide payment method</Text>
                <CardDivider/>
                <Box paddingBlockStart="400">
                  <FormLayout>
                    <TextField
                      value={state.paymentMethodName}
                      label="Payment method"
                      requiredIndicator
                      onChange={(value) => setState(update(state, { paymentMethodName: { $set: value }}))}
                    />
                    {error && <InlineError message={error} fieldID="paymentMethod" />}
                  </FormLayout>
                </Box>
              </LegacyCard>
              <LegacyCard sectioned>
                <Text variant="bodyMd" as="h6" fontWeight="semibold">Display conditions</Text>
                <CardDivider/>
                <FormLayout>
                  <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"customization"} factsOptionsValue={[
                  { label: "Cart products", value: "cartProducts" },
                  { label: "Cart total", value: "cartTotal" },
                  { label: "Customer", value: "customer" },
                ]} />
                </FormLayout>
              </LegacyCard>
              
            </FormLayout>
              </Form>
            </div>
        </Layout.Section>
       
      </Layout>
  )
}

export default HidePaymentMethodsForm